import React from 'react'
import { Button } from 'antd'

import './index.scss'

function DefaultButton(props) {
  const { title, colorType, width, action, className, subtitle, disabled = false, icon, isNotPayingBtn } = props
  const widthSet = width || '100%'
  return (
    <Button
      disabled={disabled}
      className={`custom-df-btn default-btn ${colorType} ${className} ${isNotPayingBtn ? 'noBorder' : ''}`}
      style={{ width: widthSet }}
      onClick={() => {
        action()
      }}>
      <span className='d-flex justify-content-center align-items-center'>
        {icon} {title} {subtitle}
      </span>
    </Button>
  )
}
export default DefaultButton

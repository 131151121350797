import Request from './request'

export default class UserInsuranceService {
  static async getInsuranceList(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUserInsurance/user/getList',
        data: data,
        query: null
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async createOrderPaymentMotobikeInsurance(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/createOrderPaymentInsurtanceMotobike',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async createOrderPaymentCarInsurance(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/createOrderPaymentInsurtanceTNDSOto',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
}


import React from 'react'
import { ReactComponent as EditIcon } from './../../assets/new-icons/Edit.svg'
import { ReactComponent as RegistrationDeadlineIcon } from './../../assets/new-icons/RegistrationDeadline.svg'
import { ReactComponent as BHTNDSdeadlineIcon } from './../../assets/new-icons/BHTNDSdeadline.svg'
import { ReactComponent as BHTVdeadlineIcon } from './../../assets/new-icons/BHTVdeadline.svg'
import { ReactComponent as ColdPenaltyIcon } from './../../assets/new-icons/Frame 2120412722.svg'
import { ReactComponent as DeleteIcon } from './../../assets/new-icons/Delete2.svg'
import { VIHCLE_TYPES } from 'constants/global'
import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'
import './../../assets/scss/typography.scss'
import { DatePicker, Space, Modal } from 'antd'
import UserVihcleService from 'services/userVihcleService'
import { useState } from 'react'
import PopupMessage from 'components/PopupMessageAlert'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

let expiryDate
let expiryDateBHTV
let expiryDateBHTNDS

function VihcleViewWidget({ ele, history, removeItem }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalExpiryDate, setModalExpiryDate] = useState(false)
  const [modalExpiryDateBHTV, setModalExpiryDateBHTV] = useState(false)
  const [modalExpiryDateBHTNDS, setModalExpiryDateBHTNDS] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const { t: translation } = useTranslation()

  const showModal = (id) => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    {
      modalExpiryDate && handleUpdateExpiryDate(ele)
    }
    {
      modalExpiryDateBHTV && handleUpdateExpiryDateBH(ele)
    }
    {
      modalExpiryDateBHTNDS && handleUpdateExpiryDateBH(ele)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onChange = (date, dateString) => {
    if (dateString) {
      expiryDate = moment(dateString, DATE_DISPLAY_FORMAT).format(DATE_DISPLAY_FORMAT).toString()
    }
  }
  const onChangeBHTV = (date, dateString) => {
    expiryDateBHTV = moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD')
  }
  const onChangeBHTNDS = (date, dateString) => {
    expiryDateBHTNDS = Number.parseInt(moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD'))
  }

  const handleUpdateExpiryDate = (ele) => {
    UserVihcleService.updateVihcle({
      id: ele?.appUserVehicleId,
      data: {
        vehicleExpiryDate: expiryDate
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }
  const handleUpdateExpiryDateBH = (ele) => {
    UserVihcleService.updateVihcleExpiryDate({
      id: ele?.appUserVehicleId,
      data: {
        appUserId: ele?.appUserId,
        vehicleIdentity: ele?.vehicleIdentity,
        vehicleExpiryDateBHTNDS: expiryDateBHTNDS,
        vehicleExpiryDateBHTV: expiryDateBHTV
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }

  const automatedTrafficFineNotificationRegistered = ele?.customerSchedule?.filter(e => e.notifyType === 1);
  const isAutomatedTrafficFineNotificationRegistered = automatedTrafficFineNotificationRegistered?.length > 0;

  const getLargestDate = (schedules) => {
    return schedules.reduce((latest, current) => {
      const currentDate = new Date(current.dateSchedule.split('/').reverse().join('-'));
      return currentDate > latest ? currentDate : latest;
    }, new Date("1970-01-01"));
  };

  const largestDate = moment(getLargestDate(automatedTrafficFineNotificationRegistered)).format('DD/MM/YYYY');

  return (
    <div>
      <div className="vihcle-widget__detail">
        <div>{VIHCLE_TYPES.find((e) => e.value == ele?.vehicleType)?.icon || ''}</div>
        <div className={`plate text-normal ${ele?.vehiclePlateColor != 'RED' ? ele?.vehiclePlateColor : 'WHITE BORDER'}`}>{ele?.vehicleIdentity}</div>
        {/* tạm ẩn */}
        {/* {ele.vehicleHash && <QRCodeCanvas value={window.location.host + `/vihclesInformation?hash=${ele.vehicleHash}`} size={35} />} */}
      </div>
      <div className="vihcle-widget-more">
        <div className="d-flex j-sb">
          <div
            className="w-50 mt-3 d-flex align-items-center pointer"
            onClick={() => {
              showModal(ele?.appUserVehicleId)
              setModalExpiryDate(true)
              setModalExpiryDateBHTV(false)
              setModalExpiryDateBHTNDS(false)
            }}>
            <div className="me-1">
              <RegistrationDeadlineIcon />
            </div>
            <div>
              <div className="vihcle-widget-title text-very-small">{translation('handk')}</div>
              <div className="vihcle-widget-text text-small">{ele?.vehicleExpiryDate || '--/--/--'}</div>
            </div>
          </div>
          <div
            className="w-50 mt-3 d-flex align-items-center pointer"
            onClick={() => {
              showModal(ele?.appUserVehicleId)
              setModalExpiryDate(false)
              setModalExpiryDateBHTV(true)
              setModalExpiryDateBHTNDS(false)
            }}>
            <div className="me-1">
              <BHTVdeadlineIcon />
            </div>
            <div>
              <div className="vihcle-widget-title text-very-small">{translation('BHTV')}</div>
              <div className="vihcle-widget-text text-small">
                {ele?.vehicleExpiryDateBHTV ? moment(ele?.vehicleExpiryDateBHTV, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : '--/--/--'}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex j-sb">
          <div
            className="w-50 mt-3 d-flex align-items-center pointer"
            onClick={() => {
              showModal(ele?.appUserVehicleId)
              setModalExpiryDate(false)
              setModalExpiryDateBHTV(false)
              setModalExpiryDateBHTNDS(true)
            }}>
            <div className="me-1">
              <BHTNDSdeadlineIcon />
            </div>
            <div>
              <div className="vihcle-widget-title text-very-small">{translation('TNDS')}</div>
              <div className="vihcle-widget-text text-small">
                {ele?.vehicleExpiryDateBHTNDS ? moment(ele?.vehicleExpiryDateBHTNDS, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : '--/--/--'}
              </div>
            </div>
          </div>
          <div className="w-50 mt-3 d-flex align-items-center">
            <div className="me-1">
              <ColdPenaltyIcon />
            </div>
            <div>
              <div className="vihcle-widget-title text-very-small">{translation('phat-nguoi')}</div>
              <div className="vihcle-widget-text text-small d-flex align-items-center gap-1">
                { isAutomatedTrafficFineNotificationRegistered ? (
                        <>
                          <span>{largestDate}</span>
                        </>
                      ) : (
                        <Link to={"/automated-traffic-fine-notification/services"}>{translation("register-now")}</Link>
                      )
                    }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="blue vihcle-widget__icon ">
        {/* Tạm ẩn */}
        {/* <span className='text-normal' style={{backgroundColor:'#0870D9'}} 
                onClick={() => history.push('/vihcles/' + ele?.appUserVehicleId)}
            ><ViewIcon className="me-1" />Xem</span> */}
        <div
          onClick={(e) => {
            e.stopPropagation()
            history.push('/regist-vihcle/' + ele?.appUserVehicleId)
          }}
          className="vihcle-btn">
          <EditIcon width={28} height={28} className="me-1" />
          {translation('edit')}
        </div>
        <div
          className="vihcle-btn"
          style={{ color: 'var(--background-iphone-popup-coler)' }}
          onClick={(e) => {
            e.stopPropagation()
            removeItem(ele?.appUserVehicleId)
          }}>
          <DeleteIcon width={28} height={28} className="me-1" />
          {translation('delete')}
        </div>
      </div>
      <Modal title="Cập nhật" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {modalExpiryDate && (
          <>
            <div style={{ margin: '0 0 5px 0' }}>{translation('end-date-dk')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydate" placeholder={translation('handk')} onChange={onChange} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTV && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tv')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTV" placeholder={translation('date-tv')} onChange={onChangeBHTV} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTNDS && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tnds')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTNDS" placeholder={translation('date-tnds')} onChange={onChangeBHTNDS} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
      </Modal>
      {errorMessage && (
        <PopupMessage
          isModalOpen={errorMessage}
          onClose={() => {
            setErrorMessage(undefined)
          }}
          text={errorMessage}></PopupMessage>
      )}
    </div>
  )
}

export default VihcleViewWidget

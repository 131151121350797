import React, { useEffect } from 'react'

import { useTranslation } from "react-i18next"

import companyInfo from 'constants/companyInfo'
import { StickyHeaderContainer } from "components/elements/header"
import { concat } from 'lodash'

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t: translation } = useTranslation()

  const LIST_RETURN_POLICY = [
    {
      title: 'PHẠM VI ÁP DỤNG',
      content: `
        <p>Chính sách hoàn trả này áp dụng cho tất cả các sản phẩm và dịch vụ được cung cấp trên website ${companyInfo.shortName} (https://ttdk.com.vn/), bao gồm nhưng không giới hạn:</p>
        <ul>
          <li>Sản phẩm vật lý (nếu có)</li>
          <li>Dịch vụ đăng kiểm</li>
          <li>Các gói dịch vụ hỗ trợ khác</li>
        </ul>
        `
    },
    {
      title: 'THỜI HẠN HOÀN TRẢ ',
      content: `
      <ul>
        <li>Sản phẩm vật lý: Khách hàng có thể yêu cầu đổi/trả trong vòng 07 ngày kể từ ngày nhận hàng nếu sản phẩm bị lỗi từ nhà sản xuất hoặc giao sai hàng.</li>
        <li>Dịch vụ đăng kiểm: Không hỗ trợ hoàn tiền sau khi đã hoàn thành đăng kiểm. Trong trường hợp dịch vụ chưa được thực hiện, khách hàng có thể yêu cầu hoàn tiền trong vòng 03 ngày kể từ ngày đặt dịch vụ.</li>
        <li>Các gói dịch vụ hỗ trợ khác: Có thể được hoàn trả trong vòng 05 ngày nếu chưa sử dụng hoặc chưa kích hoạt dịch vụ.</li>
      </ul>
      `
    },
    {
      title: 'ĐIỀU KIỆN HOÀN TRẢ',
      content: `
      <div>
        <strong>a. Sản phẩm vật lý:</strong>
        <ul>
          <li>Sản phẩm phải còn nguyên vẹn, không có dấu hiệu đã qua sử dụng.</li>
          <li>Hộp đựng, tem nhãn, phụ kiện đi kèm (nếu có) phải còn đầy đủ.</li>
          <li>Có hóa đơn mua hàng hoặc thông tin đơn hàng.</li>
          <li>Không áp dụng đổi/trả đối với sản phẩm khuyến mãi, giảm giá.</li>
        </ul>
      </div>
      <div>
        <strong>b. Dịch vụ đăng kiểm:</strong>
        <ul>
          <li>Chỉ hoàn tiền nếu khách hàng hủy dịch vụ trước khi quá trình đăng kiểm được tiến hành.</li>
          <li>Nếu quá trình đăng kiểm đã được thực hiện, khách hàng không được hoàn tiền.</li>
          <li>Nếu do lỗi từ phía ${companyInfo.shortName} khiến đăng kiểm không thể thực hiện, khách hàng sẽ được hoàn tiền 100%.</li>
        </ul>
      </div>
      <div>
        <strong>c. Các gói dịch vụ hỗ trợ khác</strong>
        <ul>
          <li>Chỉ hoàn tiền nếu dịch vụ chưa được kích hoạt hoặc sử dụng.</li>
          <li>Nếu đã sử dụng một phần dịch vụ, số tiền hoàn lại sẽ được tính dựa trên phần chưa sử dụng.</li>
        </ul>
      </div>
      `
    },
    {
        title: 'PHƯƠNG THỨC HOÀN TIỀN',
        content: `
        <ul>
          <li>Hoàn tiền qua tài khoản ngân hàng do khách hàng cung cấp.</li>
          <li>Hoàn tiền qua phương thức thanh toán ban đầu (Ví điện tử, thẻ tín dụng, v.v.).</li>
          <li>Thời gian xử lý hoàn tiền: 5-7 ngày làm việc kể từ khi yêu cầu hoàn tiền được xác nhận hợp lệ.</li>
        </ul>
        `
      },
      {
        title: 'CHI PHÍ HOÀN TRẢ',
        content: `
        <ul>
          <li>Sản phẩm vật lý: Khách hàng chịu phí vận chuyển hoàn trả (trừ trường hợp lỗi từ nhà cung cấp).</li>
          <li>Dịch vụ đăng kiểm: Không thu phí hủy dịch vụ nếu yêu cầu hủy hợp lệ.</li>
          <li>Các gói dịch vụ hỗ trợ khác: Có thể áp dụng phí xử lý hoàn trả tối đa 10% giá trị dịch vụ, tùy theo từng trường hợp cụ thể.</li>
        </ul>
        `
      },
      {
        title: 'QUY TRÌNH YÊU CẦU HOÀN TRẢ',
        content: `
        <ul>
          <li>Khách hàng liên hệ ${companyInfo.shortName} qua hotline 0343.902.960 hoặc email ttdk@info.com.vn hỗ trợ, cung cấp thông tin đơn hàng và lý do hoàn trả.</li>
          <li>${companyInfo.shortName} kiểm tra và xác nhận điều kiện hoàn trả trong vòng 2-3 ngày làm việc.</li>
          <li>Nếu đủ điều kiện, ${companyInfo.shortName} tiến hành hoàn tiền theo phương thức đã thỏa thuận.</li>
          <li>Khách hàng nhận tiền hoàn trả trong 5-7 ngày làm việc.</li>
        </ul>
        `
      },
  ]

  return (
    <>
      <StickyHeaderContainer
        showArrowLeft={true}
        title={translation('return-policy')}
      />
      <div className="container" style={{ maxWidth: 600, margin: 'auto', marginBottom: '2em' }}>
        <div className='row mt-3 pd-30-15'>
          {LIST_RETURN_POLICY.map((_item, index) => {
            return (
              <>
                <ol style={{ fontWeight: 700 }} start={index + 1} key={index + 1}>
                  <li className='text-normal' style={{fontWeight: 700}}>
                    <em>{_item.title}</em>
                  </li>
                </ol>
                <span
                  className='text-normal'
                  dangerouslySetInnerHTML={{
                    __html: _item.content
                  }}
                />
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ReturnPolicy

import React, { useState } from 'react'
import { ReactComponent as EditIcon } from './../../assets/new-icons/Edit.svg'
import { ReactComponent as DeleteIcon } from './../../assets/new-icons/Delete2.svg'
import { ReactComponent as SuccessIcon } from './../../assets/new-icons/successicon.svg'
import { ReactComponent as WarningIcon } from './../../assets/new-icons/warningicon.svg'
import { ReactComponent as InfoIcon } from './../../assets/new-icons/info.svg';

import UserVihcleService from 'services/userVihcleService'
import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'
import { VIHCLE_TYPES } from 'constants/global'
import './../../assets/scss/typography.scss'
import { DatePicker, Space, Modal, Tooltip } from 'antd';
import PopupMessage from 'components/PopupMessageAlert'
import { VEHICLE_SUB_TYPE } from 'constants/global'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { grayAlto } from 'constants/colors';
import addKeyLocalStorage from 'helper/localStorage'
import UserInsuranceService from 'services/userInsuranceService'
import { INSURTANCE_TYPE } from 'constants/global'

let expiryDate
let expiryDateBHTV
let expiryDateBHTNDS

function VihcleViewList({ele,history,removeItem}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalExpiryDate, setModalExpiryDate] = useState(false);
  const [modalExpiryDateBHTV, setModalExpiryDateBHTV] = useState(false);
  const [modalExpiryDateBHTNDS, setModalExpiryDateBHTNDS] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined)
  const { t: translation } = useTranslation()
  let userData = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
  const insuranceLink = process.env.REACT_APP_RUNTIME_MODE == 'production' 
    ? `https://baohiem.ttdk.com.vn?appUserId=${userData?.appUserId}&token=${userData?.token}` 
    : `https://ttdk-sanbox-muabaohiem.service.makefamousapp.com?appUserId=${userData?.appUserId}&token=${userData?.token}`

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    {modalExpiryDate && handleUpdateExpiryDate(ele)}
    {modalExpiryDateBHTV && handleUpdateExpiryDateBH(ele)}
    {modalExpiryDateBHTNDS && handleUpdateExpiryDateBH(ele)}
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  function getVehicleTypeName(vehicleData){
    const vehicleSubType = vehicleData?.vehicleSubType
    const vehicleType = vehicleData?.vehicleType
    let vehicle
    if(vehicleSubType){
      vehicle = VEHICLE_SUB_TYPE.find((e) => e.value == vehicleSubType)?.label
      return vehicle
    }else{
      if(vehicleType){
        vehicle = VIHCLE_TYPES.find((e) => e.value == vehicleType)?.label
        return vehicle
      }else{
      return (translation('vihcle-other'))
      }
    }
  }
  const onChange = (date, dateString) => {
  if(dateString){
    expiryDate=moment(dateString, DATE_DISPLAY_FORMAT).format(DATE_DISPLAY_FORMAT).toString()
  }
};
  const onChangeBHTV = (date, dateString) => {
    expiryDateBHTV=moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD')
  };
  const onChangeBHTNDS = (date, dateString) => {
    expiryDateBHTNDS=Number.parseInt(moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD'))
  };

  const handleUpdateExpiryDate = (ele) => {
    UserVihcleService.updateVihcle({
      id: ele?.appUserVehicleId,
      data: {
        vehicleExpiryDate: expiryDate
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if(rsMess){
          setErrorMessage(translation('update-fail'))
      }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(()=>{
          window.location.reload()
        },500)
      }
    })
  }
  const handleUpdateExpiryDateBH=(ele)=>{
    UserVihcleService.updateVihcleExpiryDate({
      id: ele?.appUserVehicleId,
      data: {
        appUserId: ele?.appUserId, 
        vehicleIdentity: ele?.vehicleIdentity,
        vehicleExpiryDateBHTNDS: expiryDateBHTNDS,
        vehicleExpiryDateBHTV: expiryDateBHTV
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if(rsMess){
          setErrorMessage(translation('update-fail'))
      }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(()=>{
          window.location.reload()
        },500)
      }
    })
  }
  const handleViewContract = (data,TNDS) => {
    let insurranceType = data?.vehicleType == VIHCLE_TYPES[3].value ? INSURTANCE_TYPE.INSURANCE_MOTORBIKE_TNDSBB : TNDS ? INSURTANCE_TYPE.INSURANCE_CAR_TNDSBB : INSURTANCE_TYPE.INSURANCE_CAR_BHTV
    UserInsuranceService.getInsuranceList({
      filter : {
        appUserId: data?.appUserId,
        appUserVehicleId: data?.appUserVehicleId,
        insurranceType
      },
      order: {
        key: "createdAt",
        value: "desc"
      }
    }).then((res) => {
      const { statusCode, data } = res
      if (statusCode === 200) {
        if(data.data[0]?.coiUrl !== null) {
            window.open(data.data[0]?.coiUrl)
        } 
      } else {
        return
      }
    })
  }
  const checkIsPastDate = (dateString) => {
    const [day, month, year] = dateString.split('/')

    const inputDate = new Date(`${month}/${day}/${year}`)
    const currentDate = new Date()

    return inputDate < currentDate
  }

  const automatedTrafficFineNotificationRegistered = ele?.customerSchedule?.filter(e => e.notifyType === 1);
  const isAutomatedTrafficFineNotificationRegistered = automatedTrafficFineNotificationRegistered?.length > 0;

  const getLargestDate = (schedules) => {
    return schedules.reduce((latest, current) => {
      const currentDate = new Date(current.dateSchedule.split('/').reverse().join('-'));
      return currentDate > latest ? currentDate : latest;
    }, new Date("1970-01-01"));
  };

  const isPastDate = checkIsPastDate(moment(ele?.vehicleExpiryDateBHTNDS, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT))

  const largestDate = moment(getLargestDate(automatedTrafficFineNotificationRegistered)).format('DD/MM/YYYY');

  return (
    <div className="box-vihcle__content">
      <div className="box-vihcle--item">
        <div className='box-vihcle--content'>
          <div className="vihcle-widget__detail">
              <div>
                  {VIHCLE_TYPES.find((e) => e.value == ele?.vehicleType)?.icon || ''}
              </div>
              <div className={`plate text-normal ${ele?.vehiclePlateColor != 'RED' ? ele?.vehiclePlateColor : 'WHITE BORDER'}`}>{ele?.vehicleIdentity}</div>
          </div>
          <div className='box-vihcle-info'>
          <div className='box-vihcle-more'>
            <div className="">
              <div className="d-flex align-items-center">
                <div className='box-vihcle-more--icon'>
                  {translation('soquanly')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small">{ele?.vehicleRegistrationCode || '...'}</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('loaipt')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small">{getVehicleTypeName(ele)}</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('nhanhieu')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small">{ele?.vehicleBrandName || '...'}</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('soloai')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small">{ele?.vehicleBrandModel || '...'}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='box-vihcle-more'>
            <div className="">
              <div className="d-flex align-items-center" onClick={()=>{
                  showModal(ele?.appUserVehicleId)
                  setModalExpiryDate(true)
                  setModalExpiryDateBHTV(false)
                  setModalExpiryDateBHTNDS(false)}}>
                <div className='box-vihcle-more--icon'>
                  {translation('handk')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small">
                      {ele?.vehicleExpiryDate ? 
                        <div className='box-vihcle-text text-view-list'>
                          {ele?.vehicleExpiryDate}
                        </div> : 
                        <div className='text-step-line pointer' onClick={() => {
                          showModal(ele?.appUserVehicleId)
                          setModalExpiryDateBHTV(true)
                        }}>
                          {translation('update')}
                        </div>
                      }
                    </div>
                </div>
              </div>
              <div className="d-flex align-items-start mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('BHTV')}:
                </div>
                <div>
                  <div className=" text-small">{ele?.vehicleExpiryDateBHTV 
                      ? 
                      <>
                        <div className='box-vihcle-text text-view-list'>
                          {moment(ele?.vehicleExpiryDateBHTV, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT)}
                        </div>              
                        <a onClick={() => handleViewContract(ele)} className='viewTableText'>{translation('view-contract')}</a>
                      </>
                    : <div className='box-vihcle-text text-view-list' style={{maxWidth: 140}}>
                      <div className='text-step-line pointer' onClick={() => {
                        showModal(ele?.appUserVehicleId)
                        setModalExpiryDateBHTV(true)
                      }}>
                        {translation('update')}
                      </div>
                      <span style={{marginRight: 1, marginLeft: 1}}>/</span>
                      <div className='text-step-line pointer' onClick={() => window.open(insuranceLink)}>
                          {translation('buy_now')}
                      </div>
                    </div>
                  }</div>
                </div>
              </div>
              <div className="d-flex align-items-start mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('TNDS')}:
                </div>
                <div>
                  <div className=" text-small">{ele?.vehicleExpiryDateBHTNDS 
                      ? 
                      <>
                        <div className='box-vihcle-text text-view-list' style={{marginRight: 2}}>
                          {moment(ele?.vehicleExpiryDateBHTNDS, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT)}
                        </div>              
                        {!isPastDate ? 
                        <div onClick={() => handleViewContract(ele)} className='text-step-line pointer'>{translation('view-contract')}</div> : 
                        <div className='text-step-line pointer' onClick={() => window.open(insuranceLink)}>
                            {translation('extendNow')}
                        </div>}
                      </>
                    : <div className='box-vihcle-text text-view-list' style={{maxWidth: 140}}>
                      <p className='text-step-line pointer' onClick={() => {
                        showModal(ele?.appUserVehicleId)
                        setModalExpiryDateBHTNDS(true)
                      }}>
                        {translation('update')}
                      </p>
                      <span style={{marginRight: 1, marginLeft: 1}}>/</span>
                      <p className='text-step-line pointer' onClick={() => window.open(insuranceLink)}>
                          {translation('buy_now')}
                      </p>
                    </div>
                  }</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <div className='box-vihcle-more--icon'>
                  {translation('phatnguoi')}:
                </div>
                <div>
                  <div className="box-vihcle-text text-small d-flex align-items-center gap-1">
                  {
                      isAutomatedTrafficFineNotificationRegistered ? (
                        <>
                          <span>{largestDate}</span>
                        </>
                      ) : (
                        <Link to={"/automated-traffic-fine-notification/services"}>{translation("buy_now")}</Link>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        </div>
        <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',gap:'10px',padding:'0 15px'}}>
          <div>
            {ele?.vehicleVerifiedInfo === 1 ? (
              <>
              <div className='success-verified'><SuccessIcon width={22} height={22}/>{translation('verified')}</div>
              </>
            ):(
              <>
                <div className='warning-verified'><WarningIcon width={15} height={15}/>{translation('unverified')}</div>
              </>
            )}
          </div>
          <div
          className="blue box-vihcle--icon "
          onClick={(e) => {
            e.stopPropagation()
          }}>
            {/* <span 
              onClick={() => history.push('/vihcles/' + ele?.appUserVehicleId)}
            ><ViewIcon className="me-1" /></span> */}
            <div 
              onClick={(e) => {
                e.stopPropagation()
                history.push('/regist-vihcle/' + ele?.appUserVehicleId)
              }}
              className='vihcle-btn'
            ><EditIcon width={28} height={28} className="me-1" />{translation('edit')}</div>
            <div className='vihcle-btn' 
            style={{color:'var(--background-iphone-popup-coler)'}}
              onClick={(e) => {
              e.stopPropagation()
              removeItem(ele?.appUserVehicleId)
              }}>
              <DeleteIcon width={28} height={28} className="me-1"/>{translation('delete')}
            </div>
          </div>
        </div>
      </div>
      <Modal title={translation('update')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {modalExpiryDate && 
        <>
          <div style={{margin:'0 0 5px 0'}}>{translation('end-date-dk')}</div>
          <Space direction="vertical" size={12}>
            <DatePicker name='expirydate' placeholder={translation('handk')} onChange={onChange} format={DATE_DISPLAY_FORMAT} />
          </Space>
        </>
        }
        {modalExpiryDateBHTV && 
        <>
          <div style={{margin:'15px 0 5px 0'}}>{translation('end-date-tv')}</div>
          <Space direction="vertical" size={12}>
            <DatePicker name='expirydateBHTV' placeholder={translation('date-tv')} onChange={onChangeBHTV} format={DATE_DISPLAY_FORMAT} />
          </Space>
        </>
        }
        {modalExpiryDateBHTNDS && 
        <>
          <div style={{margin:'15px 0 5px 0'}}>{translation('end-date-tnds')}</div>
          <Space direction="vertical" size={12}>
            <DatePicker name='expirydateBHTNDS' placeholder={translation('date-tnds')} onChange={onChangeBHTNDS} format={DATE_DISPLAY_FORMAT} />
          </Space>
        </>
        }
      </Modal>
      {errorMessage &&
        <PopupMessage isModalOpen={errorMessage} onClose={() => {setErrorMessage(undefined)}} text={errorMessage} ></PopupMessage>
      }
    </div>
  )
}

export default VihcleViewList

import React, { useEffect } from 'react'

import { useTranslation } from "react-i18next"

import companyInfo from 'constants/companyInfo'
import { StickyHeaderContainer } from "components/elements/header"
import { concat } from 'lodash'

const IntroduceCompany = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t: translation } = useTranslation()

  const LIST_INSPECTION_POLICY = [
    {
      title: 'CÔNG TY CỔ PHẦN CHUYỂN ĐỔI DỮ LIỆU SỐ SA MỘC',
      content: `
      <p>Mã số thuế: 0110033248</p>
      <p>Địa chỉ: Số nhà NV2-13, Khu đô thị Dream Town, TDP số 6, Phường Tây Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam</p>
      <p>Người ĐDPL: Nguyễn Minh Huệ</p>
      <p>Ngày hoạt động: 16/06/2022</p>
      <p>Giấy phép kinh doanh: 0110033248</p>
      <p>Email: ttdk@info.com.vn</p>
      <p>Số Hotline: 0343.902.96</p>
      `
    },
  ]

  return (
    <>
      <StickyHeaderContainer
        showArrowLeft={true}
        title={translation('Giới Thiệu Công Ty')}
      />
      <div className="container" style={{ maxWidth: 600, margin: 'auto', marginBottom: '2em' }}>
        <div className='row mt-3 pd-30-15 text-normal'>
          <p><strong>CÔNG TY CỔ PHẦN CHUYỂN ĐỔI DỮ LIỆU SỐ SA MỘC</strong></p>
          <p>Mã số thuế: 0110033248</p>
          <p>Địa chỉ: Số nhà NV2-13, Khu đô thị Dream Town, TDP số 6, Phường Tây Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam</p>
          <p>Người ĐDPL: Nguyễn Minh Huệ</p>
          <p>Ngày hoạt động: 16/06/2022</p>
          <p>Giấy phép kinh doanh: 0110033248</p>
          <p>Email: ttdk@info.com.vn</p>
          <p>Số Hotline: 0343.902.96</p>
        </div>
      </div>
    </>
  )
}

export default IntroduceCompany

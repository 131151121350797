export const STATIONS_LIST = 'STATIONS_LIST'

export const STATIONS_TYPE = {
    CENTER: 1, // Danh sach trung tam
    INTERNAL: 2, //Nội bộ TTDK
    GARAGE:3, //Bảo dưỡng ô tô
    INSPECTION_RESCUE: 4, // Cứu hộ đăng kiểm
    INSURANCE_COMPANY: 5, // Đơn vị bảo hiểm
    CONSULTING: 6, // Đơn vị tư vấn
    AFFILIATE: 7, // Đơn vị liên kết
    ADVERTISING: 8, // Đơn vị quảng cáo
    TRANSPORT_COOPERATIVE: 9, // Hợp tác xã vận tải
    USED_CAR_TRADING: 10, // Mua bán xe cũ
    SPARE_PARTS_DEALERSHIP: 11, //Mua bán phụ tùng ô tô
    PARKING_LOT:12, //Bai giu xe,
    VEHICLE_RENOVATION_COMPANY: 13, //Đơn vị cải tạo xe,
    DRIVING_SCHOOL:14, //Trường học lái xe
    CHAUFFEUR_SERVICE:15, //Dịch vụ lái xe hộ
    PARTS_MANUFACTURING_CONSULTANCY:16, //Tư vấn sản xuất phụ tùng xe,
    DRIVER_HEALTH: 17, //  Khám sức khoẻ lái xe
    CAR_EVALUATION_SERVICE: 18, // Dịch vụ định giá xe
}

import React, { useEffect } from 'react'

import { useTranslation } from "react-i18next"

import companyInfo from 'constants/companyInfo'
import { StickyHeaderContainer } from "components/elements/header"
import { concat } from 'lodash'

const InspectionPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t: translation } = useTranslation()

  const LIST_INSPECTION_POLICY = [
    {
      title: 'Thời điểm kiểm hàng',
      content: `
      <ul>
        <li>${companyInfo.shortName} chấp nhận cho khách hàng đồng kiểm với nhân viên giao hàng tại thời điểm nhận hàng. Không hỗ trợ thử hàng.</li>
        <li>Sau khi nhận hàng, khách hàng kiểm lại phát hiện sai, có thể liên lạc với bộ phận chăm sóc khách hàng để được hỗ trợ đổi trả. Lưu ý, quý khách quay video lúc mở thùng hàng để đối chiếu khi cần thiết.</li>
      </ul>
      `
    },
    {
      title: 'Phạm vi kiểm tra hàng hóa',
      content: `
      <ul>
        <li>Khách hàng được kiểm tra các sản phẩm thực nhận, đối chiếu, so sánh các sản phẩm nhận được với sản phẩm đã đặt trên đơn sau khi nhân viên ${companyInfo.shortName} xác nhận đơn hàng theo các tiêu chí: ảnh mẫu, mã sản phẩm, kích thước, màu sắc. chất liệu…</li>
        <li>Tuyệt đối không bóc, mở các hộp sản phẩm có tem niêm phong, tem đảm bảo.</li>
        <li>Không được cào lấy mã các sản phẩm có tích điểm, đổi quà. Nếu bên giao hàng không có chứng từ, hoá đơn, … thì KH có thể hoàn trả hàng miễn phí hoặc từ chối nhận hàng ngay từ đầu.</li>
      </ul>
      `
    },
    {
      title: 'Các bước xử lý khi hàng hóa nhận được không như đơn đặt hàng',
      content: `
      <ul>
        <li>Khi quý khách đồng kiểm, sản phẩm nhận được không như sản phẩm khách đặt trên đơn hàng. Xin hãy liên hệ với hotline 0343.902.960 hoặc Email: ttdk@info.com.vn để được gặp bộ phận chăm sóc khách hàng xác nhận lại đơn hàng.</li>
        <li>Trường hợp ${companyInfo.shortName} đóng sai đơn hàng theo yêu cầu của khách, khách có thể không nhận hàng, không thanh toán. Trong trường hợp đơn hàng đã thanh toán, khách hàng có thể yêu cầu gửi lại đơn mới hoặc không, ${companyInfo.shortName} sẽ hoàn lại tiền cho quý khách trong thời gian sớm nhất.</li>
        <li>Trường hợp ${companyInfo.shortName} đóng hàng đúng theo đơn hàng, nhưng khách hàng thay đổi nhu cầu, khách hàng có thể yêu cầu đổi trả và áp dụng chính sách đổi trả hàng hóa. Trường hợp này khách hàng sẽ thanh toán chi phí giao hàng (nếu có).</li>
      </ul>
      `
    },
    {
        title: 'Các kênh thông tin tiếp nhận khiếu nại của khách hàng',
        content: `
        <p>Người dùng có thể gửi email tới địa chỉ ttdk@info.com.vn hoặc gọi điện tới Hotline 0343.902.960 để điều chỉnh hoặc xóa đi dữ liệu cá nhân của mình.</p>`
      },
  ]

  return (
    <>
      <StickyHeaderContainer
        showArrowLeft={true}
        title={translation('inspection-policy')}
      />
      <div className="container" style={{ maxWidth: 600, margin: 'auto', marginBottom: '2em' }}>
        <div className='row mt-3 pd-30-15'>
          {LIST_INSPECTION_POLICY.map((_item, index) => {
            return (
              <>
                <ol style={{ fontWeight: 700 }} start={index + 1} key={index + 1}>
                  <li className='text-normal' style={{fontWeight: 700}}>
                    <em className='text-uppercase'>{_item.title}</em>
                  </li>
                </ol>
                <span
                  className='text-normal'
                  dangerouslySetInnerHTML={{
                    __html: _item.content
                  }}
                />
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default InspectionPolicy

import React from 'react'
// import { ReactComponent as BookingIcon } from "./home-booking.svg"
// import { ReactComponent as InstructionIcon } from './home-instruction.svg'
// import { ReactComponent as MoreIcon } from './home-more.svg'
// import { ReactComponent as StationIcon } from './home-station2.svg'
// import { ReactComponent as HomeCool } from './home-cool.svg'
// import { ReactComponent as HomeAdvise } from './home-advise.svg'
// import { ReactComponent as HomeRescue } from './home-rescue.svg'
// import { ReactComponent as BHTNDSIcon } from './BHTNDS.svg'
import { ReactComponent as BagIcon } from './bags.svg'
import { ReactComponent as LockIcon } from './locks.svg'
import { ReactComponent as ShieldIcon } from './shields.svg'
import { ReactComponent as UserIcon } from './userPrs.svg'
import { ReactComponent as BookingHistoryIcon } from './bookings.svg'
import { ReactComponent as VihcleIcon } from './vhicles.svg'
import { ReactComponent as FileIcon } from './instruct.svg'
import { ReactComponent as CustomerCareIcon } from './cares.svg'
import { ReactComponent as BackUpIcon } from './backups.svg'
import { ReactComponent as Feed } from './feeds.svg'
import { ReactComponent as Pay } from './pays.svg'
import { ReactComponent as VihcleIcon2 } from './home-vihcle.svg'
// import { ReactComponent as InspectionFeeIcon } from './fee_inspection_icon.svg'
// import { ReactComponent as RoadFeeIcon } from './fee_road_icon.svg'
// import { ReactComponent as InsuranceIcon } from './insurance_icon.svg'
// import { ReactComponent as NotifiBlueIcon } from './notifi-blue.svg'
// import { ReactComponent as CarIcon } from './car.svg'
// import { ReactComponent as RingIcon } from './ring-notification2.svg'

export const VIPMEMBER_THEME = {
  // bookingIcon: <BookingIcon />,
  // instructionIcon: <InstructionIcon/>,
  // moreIcon: <MoreIcon/>,
  // stationIcon: <StationIcon/>,
  // homeCool: <HomeCool/>,
  // homeAdvise: <HomeAdvise/>,
  // homeRescue: <HomeRescue/>,
  // BHTNDSIcon: <BHTNDSIcon/>,
  BagIcon:<BagIcon/>,
  LockIcon:<LockIcon/>,
  ShieldIcon:<ShieldIcon/>,
  UserIcon:<UserIcon/>,
  BookingHistoryIcon:<BookingHistoryIcon/>,
  VihcleIcon:<VihcleIcon/>,
  FileIcon:<FileIcon/>,
  CustomerCareIcon:<CustomerCareIcon/>,
  BackUpIcon:<BackUpIcon/>,
  Feed:<Feed/>,
  Pay:<Pay/>,
  VihcleIcon2:<VihcleIcon2/>,
  // // InspectionFeeIcon:<InspectionFeeIcon/>,
  // RoadFeeIcon:<RoadFeeIcon/>,
  // InsuranceIcon:<InsuranceIcon/>,
  // NotifiBlueIcon:<NotifiBlueIcon/>,
  // CarIcon:<CarIcon/>,
  // RingIcon:<RingIcon/>,
}
import React, { useEffect } from 'react'

import { useTranslation } from "react-i18next"

import companyInfo from 'constants/companyInfo'
import { StickyHeaderContainer } from "components/elements/header"
import { concat } from 'lodash'

const CustomerPaymentInformationPrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t: translation } = useTranslation()

  const LIST_PAYMENT_INFORMATION_POLICY = [
    {
      title: 'Mục đích và phạm vi áp dụng',
      content: `
        <p>Chính sách này nhằm đảm bảo mọi thông tin liên quan đến thanh toán của khách hàng được bảo mật tuyệt đối, không bị xâm phạm, sử dụng trái phép hoặc tiết lộ ra bên ngoài. Chính sách áp dụng cho tất cả các giao dịch trực tuyến và tại cửa hàng, thông qua các phương thức thanh toán như tiền mặt, thẻ tín dụng, thẻ ghi nợ, chuyển khoản ngân hàng, hoặc các nền tảng ví điện tử.</p>
      `
    },
    {
      title: 'Thu thập thông tin thanh toán',
      content: `
      <p>Thông tin thanh toán có thể bao gồm:</p>
      <ul>
        <li>Họ và tên chủ thẻ hoặc tài khoản.</li>
        <li>Số tài khoản ngân hàng hoặc thẻ tín dụng.</li>
        <li>Ngày hết hạn của thẻ.</li>
        <li>Mã bảo mật (CVV).</li>
        <li>Các thông tin khác liên quan đến giao dịch thanh toán..</li>
      </ul>
      `
    },
    {
      title: 'Cam kết bảo mật thông tin',
      content: `
      <ul>
          <li>Thông tin thanh toán được mã hóa và lưu trữ an toàn trong hệ thống đạt chuẩn bảo mật PCI DSS (Payment Card Industry Data Security Standard).</li>
          <li>Chúng tôi không lưu trữ mã bảo mật CVV sau khi giao dịch hoàn tất.</li>
          <li>Tất cả các giao dịch đều được thực hiện qua các cổng thanh toán uy tín, đảm bảo an toàn cho khách hàng.</li>
        </ul>
      `
    },
    {
        title: 'Phạm vi sử dụng thông tin thanh toán',
        content: `
        <p>Thông tin thanh toán của khách hàng chỉ được sử dụng với mục đích:</p>
        <ul>
          <li>Xử lý và xác nhận các giao dịch thanh toán.</li>
          <li>Cung cấp dịch vụ hoặc sản phẩm mà khách hàng đã đăng ký/mua.</li>
          <li>Phòng tránh gian lận, đảm bảo an toàn giao dịch..</li>
        </ul>
      `
      },
      {
        title: 'Chia sẻ thông tin',
        content: `
        <p>TTDK cam kết không chia sẻ, mua bán hay trao đổi thông tin thanh toán của khách hàng cho bên thứ ba, ngoại trừ:</p>
        <ul>
          <li>Khi có yêu cầu từ cơ quan pháp luật.</li>
          <li>Hợp tác với các đơn vị cung cấp dịch vụ thanh toán để xử lý giao dịch.</li>
        </ul>
      `
      },
      {
        title: 'Quyền lợi của khách hàng',
        content: `
        <p>Khách hàng có quyền:</p>
        <ul>
          <li>Kiểm tra, chỉnh sửa hoặc yêu cầu xóa thông tin thanh toán cá nhân bất cứ lúc nào.</li>
          <li>Yêu cầu giải thích về việc thu thập và sử dụng thông tin của mình.</li>
        </ul>
      `
      },
      {
        title: 'Liên hệ hỗ trợ',
        content: `
        <p>Nếu có bất kỳ thắc mắc hoặc yêu cầu nào liên quan đến chính sách bảo mật thông tin thanh toán, khách hàng có thể liên hệ qua:</p>
        <div>
          <div>Email: ttdk@info.com.vn</div>
          <div>Hotline: 0343.902.960</div>
          <div>Địa chỉ: Số nhà NV2-13, Khu đô thị Dream Town, TDP số 6, Phường Tây Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam</div>
        </div>
      `
      },
  ]

  return (
    <>
      <StickyHeaderContainer
        showArrowLeft={true}
        title={translation('customer-payment-information-privacy-policy')}
      />
      <div className="container" style={{ maxWidth: 600, margin: 'auto', marginBottom: '2em' }}>
        <div className='row mt-3 pd-30-15'>
          {LIST_PAYMENT_INFORMATION_POLICY.map((_item, index) => {
            return (
              <>
                <ol style={{ fontWeight: 700 }} start={index + 1} key={index + 1}>
                  <li className='text-normal' style={{fontWeight: 700}}>
                    <em className='text-uppercase'>{_item.title}</em>
                  </li>
                </ol>
                <span
                  className='text-normal'
                  dangerouslySetInnerHTML={{
                    __html: _item.content
                  }}
                />
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default CustomerPaymentInformationPrivacyPolicy

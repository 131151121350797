import React, { useEffect, useState, Fragment } from 'react'
import { Empty, Progress, message } from 'antd'
import './index.scss'
import './../../assets/scss/typography.scss'
import UserVihcleService from 'services/userVihcleService'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ViewListIcon } from './../../assets/new-icons/List Arrow Down.svg'
import { ReactComponent as ViewRoomyIcon } from './../../assets/new-icons/Widget 4.svg'
import { ReactComponent as AdjustmentsIcon } from './../../assets/new-icons/Tuning Square 2.svg'
import { ReactComponent as DownloadIcon } from './../../assets/new-icons/Download Minimalistic.svg'
import swal from 'sweetalert'
import { handleUpdateDetail } from 'actions'
import LoginService from 'services/loginService'
import image from '../../assets/icon3/vehicle.png'
import SearchList from 'components/shared/search/SearchList'
import * as XLSX from 'xlsx'
import { APP_USER_CATEGORY } from 'constants/member'
import DefaultButton from 'components/elements/button'
import { IS_ZALO_MINI_APP } from 'constants/global'
import { StickyHeaderContainer } from 'components/elements/header'
import VihcleViewWidget from './vihcleViewWidget'
import VehicleViewTable from './vehicleViewTable'
import VihcleViewList from './vihcleViewList'
import FilterVihcle from './filterVihcle'
import useClickOutside from 'hooks/useClickOutside'
import { ALL_VIHCLE_CATEGORY, VEHICLE_PLATE_COLOR } from 'constants/global'
import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'
import LoadingPopup from 'components/LoadingPopup'
import BasicTablePaging from 'components/BasicComponent/BasicTablePaging'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Circles } from '../../assets/icons/Add Circle.svg'
import ModalProgress from 'Page/Backup/ModalProgress'



const Vihcle = (props) => {
  const [listVihcle, setListVihcle] = useState([])
  const { history } = props
  const { data: UserData, isUserLoggedIn } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [firstPage, setFirstPage] = useState(1)
  const [totalData, setTotalData] = useState()
  const [searchText, setSearchText] = useState('')
  const [totalVehicleData, setTotalVehicleData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [toggleViewVihcle, setToggleViewVihcle] = useState(false)
  const { show: filterVihcle, setShow: setFilterVihcle, nodeRef: filterRef } = useClickOutside()
  const [showPopup, setShowPopup] = useState(true)
  const [isModalProgress, setisModalProgress] = useState(false);
  const [percent, setPercent] = useState(0);
  let LIMIT = toggleViewVihcle ? 5 : 20;

  const { t: translation } = useTranslation()
  const [vihcleFilter, setVihcleFilter] = useState({
    limit: LIMIT,
    skip: 0
  })
  
  const getVihcle = (params) => {
    setLoading(true)
    if (params.searchText == '') delete params.searchText
    
    UserVihcleService.getListVihcle(params).then(async (result) => {
      const { isSuccess, data, error, statusCode } = result
      if(result){
        if (isSuccess) {
          setListVihcle(data?.data || [])
          setTotalData(data?.total || 0)
          setTotalVehicleData((prev) => {
            if (prev === null) {
              return data?.total
            }
  
            return prev
          })
        }else{
          setListVihcle([])
          setTotalData(0)
        }
      }else{
        setListVihcle([])
        setTotalData(0)
      }
      setLoading(false)
    })
  }

  const handleSearch = (value) => {
    const newParams = {
      ...vihcleFilter, 
      searchText: value,
      skip:0
    }
    setFirstPage(!firstPage)
    getVihcle(newParams)
  }

  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum)
    const skip = pageNum * LIMIT - LIMIT
    setVihcleFilter({ ...vihcleFilter, skip: skip })
    getVihcle({ ...vihcleFilter, skip: skip })
  }

  const updateUser = () => {
    if (!isUserLoggedIn) return false
    LoginService.getDetailUserById({
      id: UserData?.appUserId
    }).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        dispatch(handleUpdateDetail(data))
      }
    })
  }

  const removeVihcle = (id) => {
    setTotalVehicleData(null)
    UserVihcleService.deleteVehicle({
      id: id
    }).then((result) => {
      const { isSuccess } = result
      if (isSuccess) {
        getVihcle(vihcleFilter)
        updateUser()
      }
    })
  }

  useEffect(() => {
    setVihcleFilter(prev => ({
      ...prev,
      limit: LIMIT,
      skip: 0
    }));
    setCurrentPage(1);
  },[toggleViewVihcle]);

  useEffect(() => {
    getVihcle(vihcleFilter)
  }, [vihcleFilter])

  useEffect(() => {
    dispatch({ type: 'ASSIGNING', data: totalVehicleData })
  }, [totalVehicleData])

  useEffect(() => {
    if (searchText.length > 0) {
      setShowPopup(false)
    }
    if (searchText.length === 0) {
      setShowPopup(true)
    }
  }, [listVihcle.length])

  const removeItem = (id) => {
    swal({
      title: translation('you-sure'),
      text: translation('cancel-with-vihcle'),
      icon: 'warning',
      buttons: [translation('back'), translation('confirm')],
      dangerMode: true
    }).then(function (isConfirm) {
      if (isConfirm) {
        removeVihcle(id)
      }
    })
  }

  function copyToClipboard(textToCopy) {
    message.success(translation('coppy-succ'))

    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy)
    } else {
      // text area method
      let textArea = document.createElement('textarea')
      textArea.value = textToCopy
      // make the textarea out of viewport
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej()
        textArea.remove()
      })
    }
  }
  
  const onExportExcel = async () => {
    setisModalProgress(true);
    let number = Math.ceil(totalVehicleData / LIMIT)
    let params = Array.from(Array.from(new Array(number)), (element, index) => index)
    let results = []
    async function fetchData(param) {
      const response = await UserVihcleService.getListVihcle({
        filter: {},
        skip: param * 100,
        limit: 100,
        order: vihcleFilter.order || undefined
      })
      const data = await response.data.data
      return data
    }
    
    try {
      for (const [index, param] of params.entries()) {
        const result = await fetchData(param)
        results = [...results, ...result]
        const progress = ((index + 1) / params.length) * 100;
        setPercent(progress);
      }

      const convertedData = results.map((station) => {
        return {
          'Biển số xe': station?.vehicleIdentity,
          'Màu biển số': station?.vehiclePlateColor
            ? VEHICLE_PLATE_COLOR.find((e) => e.value == station?.vehiclePlateColor)?.label
            : 'Chưa xác định màu biển số',
          'Ngày hết hạn': station?.vehicleExpiryDate,
          'Loại phương tiện': station?.vehicleSubCategory
            ? ALL_VIHCLE_CATEGORY.find((e) => e.value == station?.vehicleSubCategory)?.label
            : 'Phương tiện chưa xác định',
          'Hạn BH thân vỏ': station?.vehicleExpiryDateBHTV ? moment(station?.vehicleExpiryDateBHTV, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : 'null',
          'Hạn BHTNDS': station?.vehicleExpiryDateBHTNDS ? moment(station?.vehicleExpiryDateBHTNDS, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : 'null'
        }
      })

      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(convertedData)
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet')
      XLSX.writeFile(wb, 'phuongtien.xlsx')

    } finally {
      setisModalProgress(false);
      setPercent(0);
    }
  }

  return (
    <div>
      <div className="w-100 vihcle-main">
        <div className="bookingHistory-header">
          <StickyHeaderContainer title={translation('list-vihcle')} />
        </div>
        <div className="bookingHistory-main">
          <div style={{ padding: '10px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {totalVehicleData > 0 && <SearchList placeholder={translation('inputlicensePlates')} className="m-1 w-100" onSearch={handleSearch} />}
            <DefaultButton
              className="w-50 registration-btn"
              colorType="dark"
              title={translation('add-vihcle')}
              icon={<Circles />}
              action={() => {
                history.push('/check-vihcle?service=/regist-vihcle')
              }}
            />
            <div style={{ height: '40px' }} />
          </div>
          <div className="more-opction">
          <div style={{ fontSize: '18px', fontWeight : 700 }}>
              Danh sách xe 
              {/* <span style={{ fontSize : '12px', fontWeight : 400, color : '#686770', paddingLeft : '10px'}}>{UserData.appUserCategory == 2 ? translation('sum-vihcle') + `: ${totalVehicleData}` : translation('vihcle') + `: ${totalVehicleData}/5`}</span> */}
              <span style={{ fontSize: '12px', fontWeight: 400, color: '#686770', paddingLeft: '10px'}}>
                {translation('vihcle') + `: ${totalVehicleData}`}
              </span>
            </div>
            <div className="more-opction__icon">
              {toggleViewVihcle && (
                <span
                  onClick={() => {
                    setToggleViewVihcle(false)
                  }}>
                  <ViewRoomyIcon></ViewRoomyIcon>
                </span>
              )}
              {!toggleViewVihcle && (
                <span
                  onClick={() => {
                    setToggleViewVihcle(true)
                  }}>
                  <ViewListIcon></ViewListIcon>
                </span>
              )}
              <span onClick={onExportExcel}>
                <DownloadIcon></DownloadIcon>
              </span>
              <span
                ref={filterRef}
                style={{ position: 'relative' }}
                onClick={() => {
                  setFilterVihcle(!filterVihcle)
                }}>
                <AdjustmentsIcon></AdjustmentsIcon>
                {filterVihcle && <FilterVihcle setCurrentPage={setCurrentPage} setVihcleFilter={setVihcleFilter}></FilterVihcle>}
              </span>
            </div>
          </div>
          {/* {UserData.appUserCategory === APP_USER_CATEGORY.PERSONAL_ACCOUNT ? (
            <div className="hint text-very-small">
              <div>{translation('list-vihcle-note')}</div>
            </div>
          ) : (
            <></>
          )} */}
          <div style={{ padding: '15px' }}>
            <div>
              {isLoading && <LoadingPopup />}
              {listVihcle.length > 0
                ? !isLoading &&
                  listVihcle.map((el) => {
                    return (
                      <Fragment key={el?.appUserVehicleId}>
                        {toggleViewVihcle && (
                          <div
                            className="box-vihcle"
                            style={{border: el.vehicleVerifiedInfo === 1 ? '1px solid rgba(0, 104, 255, 0.3)' : '1px solid #ededed'}}
                            // tạm ẩn
                            // onClick={() => history.push('/vihcles/' + ele?.appUserVehicleId)}
                          >
                            {/* <VihcleViewList ele={ele} history={history} removeItem={removeItem}></VihcleViewList> */}
                            <VihcleViewList ele={el} history={history} removeItem={removeItem}></VihcleViewList>
                          </div>
                        )}

                        {
                        // !toggleViewVihcle && (
                        //   <div
                        //     className="vihcle-widget"
                        //     style={{border: ele.vehicleVerifiedInfo === 1 ? '1px solid rgba(0, 104, 255, 0.3)' : '1px solid #ededed'}}
                        //     // tạm ẩn
                        //     // onClick={() => history.push('/vihcles/' + ele?.appUserVehicleId)}
                        //   >
                        //     {/* <VihcleViewWidget ele={ele} history={history} removeItem={removeItem}></VihcleViewWidget> */}
                        //     <VehicleViewTable ele={listVihcle} history={history} removeItem={removeItem}></VehicleViewTable>
                        //   </div>
                        // )
                        }
                        
                      </Fragment>
                    )
                  })
                : totalVehicleData > 0 && <Empty description="Chưa có dữ liệu" />}
                  {!toggleViewVihcle && (
                      <div
                         // className="vihcle-widget"
                         // style={{border: ele.vehicleVerifiedInfo === 1 ? '1px solid rgba(0, 104, 255, 0.3)' : '1px solid #ededed'}}
                          // tạm ẩn
                        // onClick={() => history.push('/vihcles/' + ele?.appUserVehicleId)}
                      >
                         {/* <VihcleViewWidget ele={ele} history={history} removeItem={removeItem}></VihcleViewWidget> */}
                         <VehicleViewTable ele={listVihcle} history={history} removeItem={removeItem} skip={vihcleFilter.skip}></VehicleViewTable>
                      </div>
                    )}
              {totalVehicleData === 0 && showPopup && (
                <div className="text-center text-blue">
                  <>{!IS_ZALO_MINI_APP && <img src={image} loading="lazy" alt="image" className="image__vehicle" />} </>
                  <div className="title-upcase text-x-large blue">{translation('list-vihcle-note2')}</div>
                  <div className="title-upcase text-x-large red">{translation('list-vihcle-note1')}</div>
                </div>
              )}
              {UserData.appUserCategory === APP_USER_CATEGORY.COMPANY_ACCOUNT && listVihcle.length > 0 ? (
                <>
                  <BasicTablePaging firstPage={firstPage} handlePaginations={handleChangePage} count={listVihcle.length < LIMIT}></BasicTablePaging>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      { isModalProgress === true &&
        <ModalProgress visible={isModalProgress} setVisible={setisModalProgress} percent={percent}/>
      }
    </div>
  )
}

export default Vihcle

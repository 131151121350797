export const PAYMENT_NEW = 'New';
export const PAYMENT_PROCESSING = 'Processing';
export const PAYMENT_PENDING = 'Pending';
export const PAYMENT_FAILED = 'Failed';
export const PAYMENT_SUCCESS = 'Success';
export const PAYMENT_CANCELED = 'Canceled';

export const ORDER_PAYMENT_STATUS_COLOR = [
  { value: undefined, },
  { value: 'New', color: '#0dcaf0' },
  { value: 'Processing', color: '#0d6efd' },
  { value: 'Pending', color: '#ffc107' },
  { value: 'Failed', color: '#ff6500' },
  { value: 'Success', color: '#34aa44' },
  { value: 'Canceled', color: '#FF1001' }
]

export default {
  name: 'CÔNG TY CỔ PHẦN CHUYỂN ĐỔI DỮ LIỆU SỐ SA MỘC',
  shortName: 'TTDK',
  globalName: 'SA MOC DIGITAL DATA TRANSFORMATION JOINT STOCK COMPANY',
  address: 'Số nhà NV2-13, Khu đô thị Dream Town, TDP số 6, Phường Tây Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam',
  phoneNumber: '0343.902.960',
  hotline: '1800.6601',
  email: 'ttdk@info.com.vn',
  lastUpdatedAt: '2025-02-12 14:42:57',
  managedBy: 'Chi cục Thuế Quận Nam Từ Liêm',
  taxId: '0110033248',
  director: 'Nguyễn Minh Huệ'
}

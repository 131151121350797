import { DatePicker, Modal, Space, Table, Tooltip } from 'antd'
import PopupMessage from 'components/PopupMessageAlert'
import { BIG_COLUMN_WIDTH, DATE_DISPLAY_FORMAT, NORMAL_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from 'constants/dateFormats'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import UserVihcleService from 'services/userVihcleService'
import { ReactComponent as DeleteIcon } from './../../assets/new-icons/Delete2.svg'
import { ReactComponent as EditIcon } from './../../assets/new-icons/Edit.svg'
import { ReactComponent as DefaultXe } from './../../assets/new-icons/Car default.svg'
import './../../assets/scss/typography.scss'
import { VIHCLE_TYPES } from 'constants/global'
import { ReactComponent as InfoIcon } from './../../assets/new-icons/info.svg'
import { ExceptionOutlined } from '@ant-design/icons'
import { ReactComponent as SuccessIcon } from './../../assets/new-icons/successicon.svg'
import { ReactComponent as WarningIcon } from './../../assets/new-icons/warningicon.svg'
import uploadService from 'services/uploadService';
import addKeyLocalStorage from 'helper/localStorage'
import UserInsuranceService from 'services/userInsuranceService'
import { VERY_BIG_COLUMN_WIDTH } from 'constants/dateFormats'
import { INSURTANCE_TYPE } from 'constants/global'
import { EXTRA_BIG_COLUMND_WITDTH } from 'constants/dateFormats'
import styled from 'styled-components'
import { VEHICLE_SUB_TYPE } from 'constants/global'

let expiryDate
let expiryDateBHTV
let expiryDateBHTNDS

function VehicleViewTable({ ele, history, removeItem, skip }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalExpiryDate, setModalExpiryDate] = useState(false)
  const [modalExpiryDateBHTV, setModalExpiryDateBHTV] = useState(false)
  const [modalExpiryDateBHTNDS, setModalExpiryDateBHTNDS] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [item, setItem] = useState({})
  const { t: translation } = useTranslation()
  let userData = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
  const insuranceLink = process.env.REACT_APP_RUNTIME_MODE == 'production' 
    ? `https://baohiem.ttdk.com.vn?appUserId=${userData?.appUserId}&token=${userData?.token}` 
    : `https://ttdk-sanbox-muabaohiem.service.makefamousapp.com?appUserId=${userData?.appUserId}&token=${userData?.token}`

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    {
      modalExpiryDate && handleUpdateExpiryDate(item)
    }
    {
      modalExpiryDateBHTV && handleUpdateExpiryDateBH(item)
    }
    {
      modalExpiryDateBHTNDS && handleUpdateExpiryDateBH(item)
    }
  }
  const handleViewCriminalDetail = async (record) => {
    const { appUserVehicleId: id, vehicleIdentity } = record
    const dataFilter = {
      filter: {
        customerRecordPlatenumber: vehicleIdentity
      }
    }
    try {
      const result = await uploadService.getCrimeRecords(dataFilter);
      const { isSuccess, data } = result
      if (isSuccess) {
        history.push('/criminal-record-detail', { data: data.data, id });
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onChange = (date, dateString) => {
    if (dateString) {
      expiryDate = moment(dateString, DATE_DISPLAY_FORMAT).format(DATE_DISPLAY_FORMAT).toString()
    }
  }
  const onChangeBHTV = (date, dateString) => {
    expiryDateBHTV = moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD')
  }
  const onChangeBHTNDS = (date, dateString) => {
    expiryDateBHTNDS = Number.parseInt(moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD'))
  }

  const checkIsPastDate = (dateString) => {
    const [day, month, year] = dateString.split('/')

    const inputDate = new Date(`${month}/${day}/${year}`)
    const currentDate = new Date()

    return inputDate < currentDate
  }

  const handleViewContract = (data,insurranceType) => {
    UserInsuranceService.getInsuranceList({
      filter : {
        appUserId: data?.appUserId,
        appUserVehicleId: data?.appUserVehicleId,
        insurranceType
      },
      order: {
        key: "createdAt",
        value: "desc"
      }
    }).then((res) => {
      const { statusCode, data } = res
      if (statusCode === 200) {
        if(data.data[0]?.coiUrl !== null) {
            window.open(data.data[0]?.coiUrl)
        } 
      } else {
        return
      }
    })
  }

  const handleUpdateExpiryDate = (ele) => {
    UserVihcleService.updateVihcle({
      id: ele?.appUserVehicleId,
      data: {
        vehicleExpiryDate: expiryDate
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }
  const handleUpdateExpiryDateBH = (ele) => {
    UserVihcleService.updateVihcleExpiryDate({
      id: ele?.appUserVehicleId,
      data: {
        appUserId: ele?.appUserId,
        vehicleIdentity: ele?.vehicleIdentity,
        vehicleExpiryDateBHTNDS: expiryDateBHTNDS,
        vehicleExpiryDateBHTV: expiryDateBHTV
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }

  const getVehicleTypeName =(vehicleData) => {
    const vehicleSubType = vehicleData?.vehicleSubType
    const vehicleType = vehicleData?.vehicleType
    let vehicle
    if(vehicleSubType){
      vehicle = VEHICLE_SUB_TYPE.find((e) => e.value == vehicleSubType)?.label
      return vehicle
    }else{
      if(vehicleType){
        vehicle = VIHCLE_TYPES.find((e) => e.value == vehicleType)?.label
        return vehicle
      }else{
      return (translation('vihcle-other'))
      }
    }
  }

  const columns = [
    {
      title: 'STT',
      key: 'index',
      dataIndex: 'index',
      width: MIN_COLUMN_WIDTH,
      align: 'center',
      render: (_, __, index) => {
        return <div className="viewTableText text-normal">{skip ? skip + index + 1 : index + 1}</div>
      }
    },
    {
      title: 'Biển số xe',
      key: 'vehicleIdentity',
      dataIndex: 'vehicleIdentity',
      width: BIG_COLUMN_WIDTH,
      render: (_, record) => {
        const { vehicleIdentity, vehiclePlateColor } = record
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div>{VIHCLE_TYPES.find((e) => e.value == record?.vehicleType)?.icon || ''}</div>
            <div className="d-flex align-items-center gap-1">
              <div
                className={`color_licensePlates viewTableText text-normal
              ${vehiclePlateColor === 'WHITE' ? 'color_white' : ' '}
              ${vehiclePlateColor === 'BLUE' ? 'color_blue' : ' '}
              ${vehiclePlateColor === 'YELLOW' ? 'color_yellow' : ' '}
              ${vehiclePlateColor === 'RED' ? 'color_red' : ' '}
            `}>
                {vehicleIdentity}
              </div>
              <Tooltip className='bg-white' title={(<div className='bg-white'>
                {record?.vehicleVerifiedInfo === 1 ? (
                  <>
                    <div className='success-verified'><SuccessIcon width={22} height={22} />{translation('verified')}</div>
                  </>
                ) : (
                  <>
                    <div className='warning-verified'><WarningIcon width={15} height={15} />{translation('unverified')}</div>
                  </>
                )}
              </div>)}>
                {record?.vehicleVerifiedInfo === 1 ? (
                  <>
                    <div className='iconVehicleVerifiedInfo'><SuccessIcon width={20} height={20} /></div>
                  </>
                ) : (
                  <>
                    <div className='iconVehicleVerifiedInfo'><WarningIcon width={13} height={13} /></div>
                  </>
                )}
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      title: translation('soquanly'),
      key: 'vehicleRegistrationCode',
      dataIndex: 'vehicleRegistrationCode',
      className: 'expiryDateColumn',
      align: 'center',
      render: (value) => {
        return (
          <div className="viewTableText cursor text-normal">
            {value ? value : '...'}
          </div>
        )
      }
    },
    {
      title: translation('loaipt'),
      key: 'vehicleRegistrationCode',
      dataIndex: 'vehicleRegistrationCode',
      align: 'center',
      className: 'expiryDateColumn',
      render: (_, record) => {
        return (
          <div className="viewTableText cursor text-normal">
            {getVehicleTypeName(record)}
          </div>
        )
      }
    },
    {
      title: translation('nhanhieu'),
      key: 'vehicleBrandName',
      dataIndex: 'vehicleBrandName',
      align: 'center',
      className: 'expiryDateColumn',
      render: (_, record) => {
        const value = record?.vehicleBrandName
        return (
          <div className="viewTableText cursor text-normal">
            {value ? value : '...'}
          </div>
        )
      }
    },
    {
      title: translation('soloai'),
      key: 'vehicleBrandModel',
      dataIndex: 'vehicleBrandModel',
      className: 'expiryDateColumn',
      align: 'center',
      render: (_, record) => {
        const value = record?.vehicleBrandModel
        return (
          <div className="viewTableText cursor text-normal">
            {value ? value : '...'}
          </div>
        )
      }
    },
    {
      title: 'Hạn đăng kiểm',
      key: 'vehicleExpiryDate',
      dataIndex: 'vehicleExpiryDate',
      align: 'center',
      className: 'expiryDateColumn',
      render: (value, record) => {
        return (
          <div
            className="viewTableText cursor text-normal"
            onClick={() => {
              showModal()
              setItem(record)
              setModalExpiryDate(true)
              setModalExpiryDateBHTV(false)
              setModalExpiryDateBHTNDS(false)
            }}>
            {value ? value : 
              <div className='viewTableText text-step-line pointer' onClick={() => {
                showModal()
                setItem(record)
                setModalExpiryDate(true)
              }}>
                {translation('update')}
              </div>}
          </div>
        )
      }
    },
    {
      title: 'Hạn BHTV',
      key: 'vehicleExpiryDateBHTV',
      dataIndex: 'vehicleExpiryDateBHTV',
      align: 'center',
      width: '115px',
      render: (_, record) => {
        const value = record?.vehicleExpiryDateBHTV
        let insurranceType = record?.vehicleType == VIHCLE_TYPES[3].value ? INSURTANCE_TYPE.INSURANCE_MOTORBIKE_TNDSBB : INSURTANCE_TYPE.INSURANCE_CAR_BHTV
        return (
          <div className="viewTableText text-normal" style={{minWidth:'115px'}}>
            {value
              ? <div className=''>
                  {moment(value, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT)}
                  <div className='viewTableText' style={{marginLeft: 2}}>
                    <a onClick={() => handleViewContract(record,insurranceType)} className='viewTableText'>{translation('view-contract')}</a>
                  </div>
                </div>              
              : <div className='d-flex flex-column'>
                <div className='viewTableText text-step-line pointer' onClick={() => {
                  showModal()
                  setItem(record)
                  setModalExpiryDate(false)
                  setModalExpiryDateBHTV(true)
                  setModalExpiryDateBHTNDS(false)
                }}>
                  {translation('update')}
                </div>
                <span style={{marginRight: 1, marginLeft: 1}}>/</span>
                <div onClick={() => window.open(insuranceLink)}>
                  <div className='viewTableText text-step-line pointer'>
                    {translation('buy_now')}
                  </div>
                </div>
              </div>
            }
          </div>
        )
      }
    },
    {
      title: 'Hạn BH TNDS',
      key: 'vehicleExpiryDateBHTNDS',
      dataIndex: 'vehicleExpiryDateBHTNDS',
      align: 'center',
      width: '115px',
      render: (_, record) => {
        const value = record?.vehicleExpiryDateBHTNDS
        let insurranceType = record?.vehicleType == VIHCLE_TYPES[3].value ? INSURTANCE_TYPE.INSURANCE_MOTORBIKE_TNDSBB : INSURTANCE_TYPE.INSURANCE_CAR_TNDSBB
        const isPastDate = checkIsPastDate(moment(value, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT))
        return (
          <div className="viewTableText text-normal" style={{minWidth:'115px'}}>
            {value
              ? <div className=''>
                  {moment(value, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT)}
                  <div className='viewTableText' style={{marginLeft: 2}}>
                    {!isPastDate ? 
                      <a onClick={() => handleViewContract(record,insurranceType)} className='viewTableText'>{translation('view-contract')}</a> : 
                      <div onClick={() => window.open(insuranceLink)}>
                        <div className='viewTableText text-step-line pointer'>
                          {translation('extendNow')}
                        </div>
                      </div>}
                  </div>
                </div>              
              : <div className='d-flex flex-column'>
                <div className='viewTableText text-step-line pointer' onClick={() => {
                  showModal()
                  setItem(record)
                  setModalExpiryDateBHTNDS(true)
                }}>
                  {translation('update')}
                </div>
                <span style={{marginRight: 1, marginLeft: 1}}>/</span>
                <div onClick={() => window.open(insuranceLink)}>
                  <div className='viewTableText text-step-line pointer'>
                    {translation('buy_now')}
                  </div>
                </div>
              </div>
            }
          </div>
        )
      }
    },
    {
      title: 'Hạn TBPN',
      key: 'vehicleExpiryDateBHTNDS',
      dataIndex: 'vehicleExpiryDateBHTNDS',
      className: 'columnTBPN',
      align: 'center',
      render: (value, record) => {
        const automatedTrafficFineNotificationRegistered = record?.customerSchedule?.filter((e) => e.notifyType === 1)
        const isAutomatedTrafficFineNotificationRegistered = automatedTrafficFineNotificationRegistered?.length > 0

        const getLargestDate = (schedules) => {
          return schedules.reduce((latest, current) => {
            const currentDate = new Date(current.dateSchedule.split('/').reverse().join('-'))
            return currentDate > latest ? currentDate : latest
          }, new Date('1970-01-01'))
        }

        const largestDate = moment(getLargestDate(automatedTrafficFineNotificationRegistered)).format('DD/MM/YYYY')
        const isPastDate = checkIsPastDate(moment(largestDate, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT))

        return (
          <div className="">
            {isAutomatedTrafficFineNotificationRegistered ? (
              <>
                <div>
                  <span className="viewTableText text-normal">{largestDate}</span>
                </div>
              </>
            ) : (
              <Link to={'/automated-traffic-fine-notification/services'} className="viewTableText">
                {translation('buy_now')}
              </Link>
            )}
          </div>
        )
      }
    },
    {
      title: 'Cảnh báo',
      key: '',
      dataIndex: '',
      width: '140px',
      className: 'columnCriminal',
      align: 'center',
      render: (_, record) => {
        return (
          <div className="viewTableText text-normal">
            {
              record?.hasCrimimal > 0 ? (
                <div className="viewTableWaring d-flex justify-content-between align-items-center" onClick={() => handleViewCriminalDetail(record)}>
                  <ExceptionOutlined
                    style={{
                      color: 'var(--bs-danger)',
                    }}
                    className="text-large cursor"
                  />
                  <div className='cursor'>Có {record?.hasCrimimal} cảnh báo</div>
                </div>
              ) : (
                <div className="viewTableSearch" onClick={() => history.push("/kiemtraphatnguoi")}>Tra cứu</div>
              )
            }
          </div>
        )
      }
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (_, record) => {
        return (
          <div className="d-flex justify-content-between">
            <div
              onClick={(e) => {
                e.stopPropagation()
                history.push('/regist-vihcle/' + record?.appUserVehicleId)
              }}
              className="cursor">
              <EditIcon width={28} height={28} className="me-1" />
              {/* {translation('edit')}  */}
            </div>
            <div
              className="cursor"
              style={{ color: 'var(--background-iphone-popup-coler)' }}
              onClick={(e) => {
                e.stopPropagation()
                removeItem(record?.appUserVehicleId)
              }}>
              <DeleteIcon width={28} height={28} className="me-1" />
              {/* {translation('delete')} */}
            </div>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      {ele.length > 0 && <Table dataSource={ele} columns={columns} scroll={{ x: 900 }} pagination={false} />}
      <Modal title="Cập nhật" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {modalExpiryDate && (
          <>
            <div style={{ margin: '0 0 5px 0' }}>{translation('end-date-dk')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydate" placeholder={translation('handk')} onChange={onChange} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTV && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tv')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTV" placeholder={translation('date-tv')} onChange={onChangeBHTV} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTNDS && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tnds')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTNDS" placeholder={translation('date-tnds')} onChange={onChangeBHTNDS} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
      </Modal>
      {errorMessage && (
        <PopupMessage
          isModalOpen={errorMessage}
          onClose={() => {
            setErrorMessage(undefined)
          }}
          text={errorMessage}></PopupMessage>
      )}
    </div>
  )
}

export default VehicleViewTable

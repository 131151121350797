import React, { useState } from 'react'
import * as sc from './TheHeader.styled'
import Logo from '../../assets/icons/logo-without-text.png'
import { redLust } from 'constants/colors'
import { PageLayout } from 'components/PageLayout/PageLayout'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VTSS_LANG } from 'constants/localStorageKey'
import addKeyLocalStorage from 'helper/localStorage'

export const TheHeader = (props) => {

  const { t, i18n } = useTranslation()

  const [lang, setLang] = useState(window.localStorage.getItem(addKeyLocalStorage(VTSS_LANG)) || 'vi')

  const changeLanguageHandler = () => {
    const nextLang = lang === 'vi' ? 'en' : 'vi'

    window.localStorage.setItem(addKeyLocalStorage(VTSS_LANG), nextLang)
    i18n.changeLanguage(nextLang)

    setLang(nextLang)
  }

  const renderBanner = () => {
    if (props.setting.stationsLogo) {
      return (
        <Link to="/">
          <sc.Logo src={props.setting.stationsLogo || Logo} alt="station-logo" />
        </Link>
      )
    }

    return (
      <sc.CustomBanner>
        <sc.LogoContainer>
          <img alt={'logo'} height="62px" src={Logo} />
          <sc.ISO>ISO: 9001-2015</sc.ISO>
        </sc.LogoContainer>

        <sc.BannerInfo>
          <sc.TextNormal>Cục Đăng Kiểm Việt Nam</sc.TextNormal>
          <sc.TextLarge title={props.setting.stationsName}>{props.setting.stationsName}</sc.TextLarge>
          <div className="flex-between banner-footer">
            <sc.TextSmall title={props.setting.stationsAddress}>
              {t('setting.address')}: {props.setting.stationsAddress}
            </sc.TextSmall>
            <sc.TextSmall textAlign={'right'} title={props.setting.stationsHotline}>
              {t('setting.phone')}: {props.setting.stationsHotline}
            </sc.TextSmall>
          </div>
        </sc.BannerInfo>
      </sc.CustomBanner>
    )
  }

  return (
    <>
      <sc.Container className="flex-center" bgColor={redLust}>
        <sc.NavBar className="flex-center">{/* {renderBanner()} */}</sc.NavBar>
      </sc.Container>
      <PageLayout overflowHidden={false}>{props.renderTheNavbar()}</PageLayout>
    </>
  )
}

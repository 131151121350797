import React, { useEffect, useRef, useState } from 'react'
import { BANNER_LAYOUT } from 'constants/banner';
import { BANNER_TYPE } from 'constants/banner';
import { useSelector } from 'react-redux';
import "./index.scss"
import { Button, Carousel, Drawer, Space } from 'antd';
import { getBannerBySectionCache } from 'helper/getBannerBySectionCache';
import { ClickCountableArea } from 'components/BasicComponent/ClickCountableArea';
import { TARGET_ID } from 'constants/targetList';
export default function HelloBanner({
  setpopupStep
}) {
    const currentPath = window.location.pathname;
    const isHomePage = currentPath === "/"
    const carouselRef = useRef(null);
    const [banners, setBanner] = useState([])
    const [open, setOpen] = useState(() => {
        if(!isHomePage) return false
        const isOpen = JSON.parse(localStorage.getItem('helloBannerOpen'));
        if (!banners?.length) return false
        return isOpen !== null ? isOpen === true && banners.length > 0 : true;
    });
    const [currentSlide, setCurrentSlide] = useState(0);
    const isLastSlide = currentSlide === banners?.length - 1;
    useEffect(() => {
      getBannerBySectionCache(BANNER_TYPE[BANNER_LAYOUT.HELLO_SCREEN]).then(data =>{
          setBanner(data)
      })
      const isOpen = localStorage.getItem('helloBannerOpen');
      if(isOpen == 'false'){
        setpopupStep(2)
      }
    }, []);

    useEffect(() => {
        if (!banners?.length) return
        const storedBanners = JSON.parse(localStorage.getItem('banners') || null) || []

        const isDifferent = banners.some(banner1 => !storedBanners.some(banner2 => banner1?.systemPromoBannersId === banner2?.systemPromoBannersId));
        if (isDifferent && isHomePage) setOpen(true)
    }, [banners]);
    const onClose = () => {
        setOpen(false);
        setpopupStep(2)
        localStorage.setItem('helloBannerOpen', 'false');
        localStorage.setItem('banners', JSON.stringify(banners));
    };

    const handleContinue = () => {
        if (isLastSlide) {
            onClose()
        }
        else {
            setCurrentSlide(currentSlide + 1)
            carouselRef.current.next();
        }
   }
  
  const handleBeforeChange = (from, to) => {
    setCurrentSlide(to)
  }
  return (
    <Drawer headerStyle={{display:"none"}} zIndex={1001} placement="top" closeIcon={null} width="100%" height="100%" open={open}>
      <div className="wrap-hello-banner">
        <Carousel beforeChange={handleBeforeChange} dots={'custom-dot'} ref={carouselRef} className="hello-carousel">
          {banners?.map((item, index) => (
            <div className="hello-banner" key={index}>
              <ClickCountableArea Id={TARGET_ID.HELLOBANNER.id} className="slide" link={item?.bannerUrl || '#'} target={item?.bannerUrl ? '_blank' : '_self'}>
                <img src={item.bannerImageUrl} alt={item.bannerName} />
              </ClickCountableArea>
            </div>
          ))}
        </Carousel>
        <div>
          <div className="hello-banner-btn">
            <Button onClick={handleContinue} size="large" className="primary-btn-hello-banner" type="primary">
              Tiếp Tục
            </Button>
            <Button className='rm-btn' onClick={onClose}>Bỏ Qua</Button>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'

export const formatDate = (date) => {
  return moment(date || new Date()).format(DATE_DISPLAY_FORMAT)

  // TODO: consider to remove moment lib
  // const dateObj = new Date(date);
  // const dayNumber = dateObj.getDate();

  // const {day, month, year} = {
  //   day: dayNumber >= 10 ? `${dayNumber}` : `0${dayNumber}`,
  //   month: dateObj.getMonth() + 1,
  //   year: dateObj.getFullYear(),
  // };

  // return `${day}/${month}/${year}`;
}

export const formatDateTime = (isoDate) => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0'); // Lấy ngày và thêm số 0 nếu cần
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng tính từ 0 nên cần +1
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} lúc ${hours}:${minutes}:${seconds}`;
};
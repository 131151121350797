import Request from './request'

export default class OrderServices {
  static async getOrders(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/getOrders',
        data
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async getPaymentInfo(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Order/user/getPaymentInfo',
        data
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })

  }
}
